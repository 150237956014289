<template>
    <ts-loading-banner :loading="loading">
        <div class="tw-space-y-2">
            <div class="row">
                <div class="col-md-12">
                    <div
                        class="tw-border-2 tw-border-dashed tw-p-4 tw-rounded-md tw-flex tw-items-center tw-justify-center tw-h-52"
                        :class="{ 'tw-border-blue-500': isDragOver }"
                        @drop="handleDrop"
                        @dragover.prevent="dragover"
                        @dragleave.prevent="dragleave"
                        @click.prevent="openFileBrowser"
                    >
                        <p class="tw-mb-0 tw-text-gray-300" v-if="!isImage">
                            Drop file here or click
                        </p>
                        <img
                            v-if="isImage"
                            :src="previewURL"
                            alt="Preview"
                            class="tw-object-contain"
                        />
                    </div>
                    <input
                        type="file"
                        ref="inputFile"
                        @change="handleFileSelection"
                    />
                </div>
            </div>
            <div class="row" v-if="$whois.admin() || $whois.agency()">
                <div class="col-md-12 tw-space-y-1">
                    <label class="required">{{
                        $t("productProfile.shopName")
                    }}</label>
                    <a-select
                        v-model="model.shop_id"
                        :options="shopList"
                        showSearch
                        :filterOption="
                            (input, option) =>
                                option.componentOptions.children[0].text
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                        "
                        class="tw-w-full"
                        :placeholder="$t('productProfile.shopName')"
                        @change="onShopChange"
                    ></a-select>
                    <div
                        class="tw-text-red-600 tw-text-xs"
                        v-if="errors.has('shop_id')"
                    >
                        {{ errors.first("shop_id") }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 tw-space-y-1">
                    <label class="required">{{
                        $t("productProfile.productCategory")
                    }}</label>
                    <a-select
                        v-model="model.category_id"
                        :options="productCategoryList"
                        showSearch
                        :filterOption="
                            (input, option) =>
                                option.componentOptions.children[0].text
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                        "
                        class="tw-w-full"
                        @change="onCategoryChange"
                        :placeholder="$t('productProfile.productCategory')"
                    ></a-select>
                    <div
                        class="tw-text-red-600 tw-text-xs"
                        v-if="errors.has('category_id')"
                    >
                        {{ errors.first("category_id") }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 tw-space-y-1">
                    <label>{{ $t("productProfile.nameKh") }}</label>
                    <input
                        v-model="model.product_name_kh"
                        type="text"
                        class="form-control"
                        :placeholder="$t('productProfile.nameKh')"
                    />
                    <div
                        class="tw-text-red-600 tw-text-xs"
                        v-if="errors.has('product_name_kh')"
                    >
                        {{ errors.first("product_name_kh") }}
                    </div>
                </div>
                <div class="col-md-6 tw-space-y-1">
                    <label class="required">{{
                        $t("productProfile.nameEn")
                    }}</label>
                    <input
                        v-model="model.product_name_en"
                        type="text"
                        class="form-control"
                        :placeholder="$t('productProfile.nameKh')"
                    />
                    <div
                        class="tw-text-red-600 tw-text-xs"
                        v-if="errors.has('product_name_en')"
                    >
                        {{ errors.first("product_name_en") }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 tw-space-y-1">
                    <label>{{ $t("productProfile.originCode") }}</label>
                    <input
                        v-model="model.original_code"
                        type="text"
                        class="form-control"
                        :placeholder="$t('productProfile.originCode')"
                    />
                    <div
                        class="tw-text-red-600 tw-text-xs"
                        v-if="errors.has('original_code')"
                    >
                        {{ errors.first("original_code") }}
                    </div>
                </div>
                <div class="col-md-6 tw-space-y-1">
                    <label class="required">{{
                        $t("productProfile.barcode")
                    }}</label>
                    <input
                        v-model="model.barcode"
                        type="text"
                        class="form-control"
                        :placeholder="$t('productProfile.barcode')"
                    />
                    <div
                        class="tw-text-red-600 tw-text-xs"
                        v-if="errors.has('barcode')"
                    >
                        {{ errors.first("barcode") }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 tw-space-y-1">
                    <label class="required">{{
                        $t("productProfile.currency")
                    }}</label>
                    <a-select
                        v-model.number="model.currency_id"
                        :options="currencyList"
                        showSearch
                        :filterOption="
                            (input, option) =>
                                option.componentOptions.children[0].text
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                        "
                        class="tw-w-full"
                        :placeholder="$t('productProfile.productCategory')"
                    ></a-select>
                    <div
                        class="tw-text-red-600 tw-text-xs"
                        v-if="errors.has('currency_id')"
                    >
                        {{ errors.first("currency_id") }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="tw-border tw-rounded-md tw-p-2">
                        <div
                            class="tw-flex tw-justify-between tw-items-center tw-mb-2"
                        >
                            <h6 class="tw-text-blue-700 tw-uppercase required">
                                {{ $t("productProfile.uom") }}
                            </h6>
                            <a href="#" @click.prevent="addCategory"
                                ><i class="fa fa-plus"></i
                            ></a>
                        </div>
                        <div class="tw-grid tw-grid-cols-2 tw-gap-2">
                            <template
                                v-for="(m, index) in model.unit_of_measures"
                            >
                                <div :key="'row_' + index">
                                    <a-select
                                        v-model="m.uom_id"
                                        class="tw-w-full"
                                        :options="uomList(m.uom_id)"
                                        :placeholder="$t('productProfile.unit')"
                                    ></a-select>
                                    <div
                                        class="tw-text-red-600 tw-text-xs"
                                        v-if="
                                            errors.has(
                                                'unit_of_measures.' +
                                                    index +
                                                    '.uom_id'
                                            )
                                        "
                                    >
                                        {{
                                            errors.first(
                                                "unit_of_measures." +
                                                    index +
                                                    ".uom_id"
                                            )
                                        }}
                                    </div>
                                </div>
                                <div :key="'row_2' + index">
                                    <input
                                        v-model.number="m.uom_quantity"
                                        type="number"
                                        class="form-control"
                                        :placeholder="$t('productProfile.qty')"
                                    />
                                    <div
                                        class="tw-text-red-600 tw-text-xs"
                                        v-if="
                                            errors.has(
                                                'unit_of_measures.' +
                                                    index +
                                                    '.uom_quantity'
                                            )
                                        "
                                    >
                                        {{
                                            errors.first(
                                                "unit_of_measures." +
                                                    index +
                                                    ".uom_quantity"
                                            )
                                        }}
                                    </div>
                                </div>
                            </template>
                        </div>
                        <div
                            class="tw-text-red-600 tw-text-xs"
                            v-if="errors.has('unit_of_measures')"
                        >
                            {{ errors.first("unit_of_measures") }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 tw-flex tw-justify-end tw-space-x-3">
                    <ts-button
                        class="mr-2"
                        @click.prevent="() => $emit('cancel')"
                    >
                        {{ $t("cancel") }}
                    </ts-button>
                    <ts-button
                        v-if="!isUpdate"
                        outline
                        color="info"
                        @click.prevent="onSaveAddNew"
                        >{{ $t("saveAddNew") }}</ts-button
                    >
                    <ts-button
                        v-if="!isUpdate"
                        color="primary"
                        @click.prevent="onSave"
                        >{{ $t("save") }}</ts-button
                    >
                    <ts-button
                        v-if="isUpdate"
                        color="primary"
                        @click.prevent="onUpdate"
                        >{{ $t("update") }}</ts-button
                    >
                </div>
            </div>
        </div>
    </ts-loading-banner>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { Errors } from "form-backend-validation";
import { isEmpty, includes } from "lodash";

export default {
    name: "productProfileForm",
    data() {
        return {
            errors: new Errors(),
            loading: false,
            previewURL: "",
            isImage: false,
            file: undefined,
            isDragOver: false,
            model: {
                product_id: null,
                shop_id: undefined,
                barcode: "",
                original_code: "",
                product_name_kh: "",
                product_name_en: "",
                currency_id: undefined,
                unit_of_measures: [
                    {
                        uom_id: "",
                        uom_quantity: "",
                        cost: 0,
                        sale_price: 0
                    }
                ],
                category_id: undefined
            }
        };
    },
    computed: {
        ...mapState("inventory/productProfile", ["formModels", "edit_data"]),
        isUpdate() {
            return !isEmpty(this.edit_data);
        },
        uomList: vm => uomId => {
            if (!vm.formModels.uom) return [];

            if (uomId != undefined) {
                return vm.formModels.uom
                    .filter(
                        u =>
                            !includes(
                                vm.model.unit_of_measures
                                    .filter(uom => uom.uom_id != uomId)
                                    .map(el => el.uom_id),
                                u.uom_id
                            )
                    )
                    .map(el => ({
                        value: el.uom_id,
                        label: `${el.uom_name_kh} ${el.uom_name_en}`
                    }));
            }
            return vm.formModels.uom.map(el => ({
                value: el.uom_id,
                label: `${el.uom_name_kh} ${el.uom_name_en}`
            }));
        },
        shopList() {
            if (!this.formModels.shop) return [];

            return this.formModels.shop.map(el => ({
                value: el.shop_id,
                label: el.shop_name
            }));
        },
        productCategoryList() {
            if (!this.formModels.productCategory) return [];

            return this.formModels.productCategory.map(el => ({
                value: el.category_id,
                label: el.category_name_en
            }));
        },
        currencyList() {
            if (!this.formModels.currency) return [];

            return this.formModels.currency.map(el => ({
                value: el.currency_id,
                label: el.currency_code
            }));
        }
    },
    created() {
        this.fetchFormView({ params: {}, index: undefined });
    },
    methods: {
        ...mapActions("inventory/productProfile", [
            "getFormViewData",
            "store",
            "update",
            "uploadFile"
        ]),
        fetchFormView({ params, index }) {
            this.loading = true;
            this.getFormViewData({ params: params, index: index })
                .then(() => {
                    this.setEditData();
                })
                .catch(err => {
                    this.$notify({ type: "error", text: err.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        onSave() {
            this.errors = new Errors();
            this.loading = true;
            this.store(this.model)
                .then(response => {
                    this.onUploadFile(response.product_id).then(() => {
                        this.$notify({
                            type: "success",
                            text: response.message
                        });
                        this.$emit("cancel");
                    });
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.$notify({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        onSaveAddNew() {
            this.errors = new Errors();
            this.loading = true;
            this.store(this.model)
                .then(response => {
                    this.onUploadFile(response.product_id).then(() => {
                        this.$notify({
                            type: "success",
                            text: response.message
                        });
                        this.clearInput();
                    });
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.$notify({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        onUpdate() {
            this.errors = new Errors();
            this.loading = true;
            this.update({
                id: this.model.product_id,
                data: this.model
            })
                .then(response => {
                    this.onUploadFile(response.product_id).then(() => {
                        this.$notify({
                            type: "success",
                            text: response.message
                        });
                        this.$emit("cancel");
                    });
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.$notify({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        clearInput() {
            this.model.shop_id = undefined;
            this.model.barcode = "";
            this.model.original_code = "";
            this.model.product_name_kh = "";
            this.model.product_name_en = "";
            this.model.currency_id = "";
            this.model.category_id = "";
            this.model.unit_of_measures = [
                {
                    uom_id: "",
                    uom_quantity: "",
                    cost: 0,
                    sale_price: 0
                }
            ];
            this.$refs.inputFile.value = "";
            this.previewURL = "";
            this.isImage = "";
            this.file = undefined;
            this.isDragOver = false;
        },
        onShopChange(value) {
            this.model.category_id = undefined;
            this.model.barcode = "";
            this.model.unit_of_measures = [
                {
                    uom_id: "",
                    uom_quantity: "",
                    cost: 0,
                    sale_price: 0
                }
            ];
            this.fetchFormView({
                index: "productCategory,uom",
                params: {
                    shop_id: value,
                    fnName: "productCategory,uom"
                }
            });
        },
        async onCategoryChange(value) {
            this.model.barcode = "";
            this.loading = true;
            if (this.$whois.admin() && this.model.category_id) {
                await this.getFormViewData({
                    index: "barcode",
                    params: {
                        shop_id: this.model.shop_id,
                        category_id: value
                    }
                });

                this.model.barcode = this.formModels.barcode[0].code;
            }

            if (this.$whois.shop() && this.model.category_id) {
                await this.getFormViewData({
                    index: "barcode",
                    params: {
                        category_id: value
                    }
                });

                this.model.barcode = this.formModels.barcode[0].code;
            }
            this.loading = false;
        },
        addCategory() {
            this.model.unit_of_measures.push({
                uom_id: "",
                uom_quantity: "",
                cost: 0,
                sale_price: 0
            });
        },
        removeUnit(index) {
            this.model.unit_of_measures.splice(index, 1);
        },
        async setEditData() {
            if (!isEmpty(this.edit_data)) {
                await this.getFormViewData({
                    index: undefined,
                    params: {
                        shop_id: this.edit_data.shop_id
                    }
                }).then(() => {
                    this.model.product_id = this.edit_data.product_id;
                    this.model.shop_id = this.edit_data.shop_id;
                    this.model.barcode = this.edit_data.barcode;
                    this.model.original_code = this.edit_data.original_code;
                    this.model.product_name_kh = this.edit_data.product_name_kh;
                    this.model.product_name_en = this.edit_data.product_name_en;
                    this.model.currency_id = this.edit_data.currency_id;
                    this.model.cost = this.edit_data.cost;
                    this.model.sale_price = this.edit_data.sale_price;
                    this.model.category_id = this.edit_data.category_id;
                    this.model.unit_of_measures = this.edit_data.product_uom.map(
                        el => ({
                            uom_id: el.pivot.uom_id,
                            uom_quantity: el.pivot.uom_quantity,
                            cost: parseFloat(el.pivot.cost),
                            sale_price: parseFloat(el.pivot.sale_price)
                        })
                    );
                    this.isImage = this.edit_data.photo ? true : false;
                    this.previewURL = this.edit_data.photo
                        ? this.edit_data.photo
                        : "";
                });
            }
        },
        dragover() {
            this.isDragOver = true;
        },
        dragleave() {
            this.isDragOver = false;
        },
        handleDrop(e) {
            e.preventDefault();
            this.isDragOver = false;
            const droppedFile = e.dataTransfer.files[0];
            if (droppedFile && droppedFile.type.startsWith("image/")) {
                this.file = droppedFile;
                this.previewFile();
            } else {
                this.$notify({
                    type: "error",
                    text: "Please select a valid image file."
                });
            }
        },
        openFileBrowser() {
            this.$refs.inputFile.click();
        },
        handleFileSelection(event) {
            const selectedFile = event.target.files[0];
            if (selectedFile && selectedFile.type.startsWith("image/")) {
                this.file = selectedFile;
                this.previewFile();
            } else {
                this.$refs.inputFile.value = "";
                this.$notify({
                    type: "error",
                    text: "Please select a valid image file."
                });
            }
        },
        previewFile() {
            this.isImage = this.file.type.startsWith("image/");
            if (this.isImage) {
                this.previewURL = URL.createObjectURL(this.file);
            }
        },
        async onUploadFile(productId) {
            if (this.file instanceof File) {
                let formData = new FormData();
                formData.append("file", this.file);
                return await this.uploadFile({
                    id: productId,
                    data: formData
                }).catch(error => {
                    this.$notify({ type: "error", text: error.message });
                });
            }
            return new Promise(r => r("done"));
        }
    }
};
</script>
