import shop from "@/api/agency/shop";

import { baseState, baseMutations } from "@/store/mixins";
import { isEmpty } from "lodash";

export const state = {
    ...baseState,
    isActive: 1,
    shop_type_id: undefined,
    tel_chat_id: undefined,
    isStockAtJd: false,
    formModels: {}
};

export const getters = {};

export const mutations = {
    ...baseMutations,
    SET_ACTIVE(state, value) {
        state.isActive = value;
    },
    SET_SHOP_TYPE(state, value) {
        state.shop_type_id = value;
    },
    SET_VIEW_MODELS(state, { data, index }) {
        if (!index) {
            state.formModels = data;
        } else {
            state.formModels[index] = data[index];
        }
    },
    SET_IS_STOCK_AT_JD(state, value) {
        state.isStockAtJd = value;
    },
    SET_TEL_CHAT_ID(state, value) {
        state.tel_chat_id = value;
    }
};

export const actions = {
    fetch(context, attributes = {}) {
        let params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage,
                filter: {
                    search: context.state.search,
                    is_active: context.state.isActive,
                    shop_type_id: context.state.shop_type_id,
                    is_stock_at_jd: context.state.isStockAtJd,
                    telChatFilter: context.state.tel_chat_id
                }
            },
            attributes
        );
        return shop.get({ params }).then(response => {
            context.commit("RECEIVE_RESOURCES", response);
            return Promise.resolve(response);
        });
    },
    store(context, data) {
        return shop.store(data);
    },
    update(context, { id, data }) {
        return shop.update(id, data);
    },
    async find(context, id) {
        if (isEmpty(context.state.edit_data)) {
            let response = await shop.find(id);
            context.commit("SET_EDIT_DATA", response.data);
        }
    },
    upload(context, data) {
        return shop.uploadImage(data);
    },
    initStock(context, shopId) {
        return shop.initStock(shopId);
    },
    viewOnMap() {
        return shop.viewOnMap();
    },
    viewTelegramChatId() {
        return shop.viewTelegramChatId();
    },
    updateTelegramChatId(context, data) {
        return shop.updateTelegramChatId(data);
    },
    async getFormViewData(context, { params, control }) {
        let response = await shop.formModel(params);
        context.commit("SET_VIEW_MODELS", {
            data: response.data,
            index: control
        });
        return response;
    }
};
