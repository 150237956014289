<template>
    <TsPanelCustomScroll :loading="loading">
        <ts-page-title
            :title="$t('shop.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                { text: $t('shop.pageTitle'), href: '/admin/agency/shops' },
                {
                    text: $t('create'),
                    active: true
                }
            ]"
        />
        <div class="row tw-space-y-3">
            <div class="col-md-12 tw-space-y-3 tw-mb-3 sm:tw-mb-0 lg:tw-mb-0">
                <!-- basic info -->
                <BasicInfo v-model="model" :validate="errors" ref="basicinfo" />
                <User v-model="model" :validate="errors" />
            </div>
            <div class="col-md-12 tw-space-y-4">
                <!-- bank account -->
                <div class="row">
                    <div class="col-md-12">
                        <BankAccount
                            v-model="model"
                            :validate="errors"
                            class="tw-h-full"
                            style="min-height: 153px"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <ExchangeRate
                            v-model="model"
                            :validate="errors"
                            class="tw-h-full"
                            style="min-height: 153px"
                        />
                    </div>
                </div>
                <ts-panel>
                    <ts-panel-wrapper>
                        <h4
                            class="tw-mb-0 tw-p-1 tw-uppercase tw-font-semibold tw-text-gray-400 tw-underline"
                        >
                            {{ $t("agencyProfile.address") }}
                        </h4>
                        <div class="row">
                            <div class="col-md-4">
                                <AddressShop
                                    v-model="model"
                                    :validate="errors"
                                    @loading="v => (loading = v)"
                                />
                            </div>
                            <div class="col-md-8">
                                <!-- geolocation -->
                                <GeoLocation v-model="model" />
                                <div
                                    class="tw-text-red-500"
                                    v-if="
                                        errors.has('geo_location.lat') ||
                                            errors.has('geo_location.lng')
                                    "
                                >
                                    {{ errors.first("geo_location.lng") }}
                                </div>
                            </div>
                        </div>
                    </ts-panel-wrapper>
                </ts-panel>
            </div>
        </div>
        <template v-slot:footer>
            <ts-button
                @click.prevent="$router.push({ name: 'shop' })"
                :disabled="loading"
                >{{ $t("cancel") }}</ts-button
            >
            <ts-button
                color="primary"
                outline
                @click.prevent="onSaveAddNew"
                :disabled="loading"
                >{{ $t("saveAddNew") }}</ts-button
            >
            <ts-button
                color="primary"
                @click.prevent="onSave"
                :disabled="loading"
                >{{ $t("save") }}</ts-button
            >
        </template>
    </TsPanelCustomScroll>
</template>

<script>
import { Errors } from "form-backend-validation";
import AddressShop from "./components/address.vue";
import GeoLocation from "./components/geolocaiton.vue";
import BankAccount from "./components/bank-account.vue";
import BasicInfo from "./components/basic-info.vue";
import User from "./components/user.vue";
import ExchangeRate from "./components/exchange-rate.vue";

import { mapActions } from "vuex";
export default {
    name: "shopCreate",
    components: {
        AddressShop,
        GeoLocation,
        BankAccount,
        BasicInfo,
        User,
        ExchangeRate
    },
    data() {
        return {
            loading: false,
            errors: new Errors(),
            model: {
                shop_code: "",
                shop_name: "",
                phone_number: "",
                email: "",
                business_category_id: 2, // non-food
                photo: "",
                geo_location: {
                    lat: "",
                    lng: ""
                },
                province_code: undefined,
                district_code: undefined,
                commune_code: undefined,
                village_code: undefined,
                payment_info: [],
                acknowledge_tc: true,
                shop_type_id: 1, // normal
                refer_from_agency_id: undefined,
                is_active: true,
                user_name: "",
                password: null,
                password_confirmation: null,
                express_fee_usd: "",
                standard_fee_usd: "",
                express_driver_fee_usd: "",
                standard_driver_fee_usd: "",
                exchange_rate: [],
                is_stock_at_jd: false,
                fee_per_package: 0,
                location_name: undefined,
                service_type_allow: [
									2
								], // default standard
                service_paid_by: 2, // default by shop
                saler_id: undefined,
                tel_chat_id: undefined
            }
        };
    },
    methods: {
        ...mapActions("agency/shop", ["getFormViewData"]),
        fetchFormView(control, params) {
            this.loading = true;
            this.getFormViewData({ params: params, control: control })
                .then(response => {
                    let agency = response.data["agencies"].filter(
                        a => a.is_company_agency == true
                    );
                    if (agency.length) {
                        this.model.refer_from_agency_id = agency[0].agency_id;
                    }
                })
                .catch(err => {
                    this.$notify({ type: "error", text: err.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        onSaveAddNew() {
            this.errors = new Errors();
            this.loading = true;
            this.$store
                .dispatch("agency/shop/store", this.model)
                .then(async response => {
                    await this.uploadLogo(response.shop_id);
                    this.$notify({ type: "success", text: response.message });
                    this.clearInput();
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.$notify({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        onSave() {
            this.errors = new Errors();
            this.loading = true;
            this.$store
                .dispatch("agency/shop/store", this.model)
                .then(async response => {
                    await this.uploadLogo(response.shop_id);
                    this.$notify({ type: "success", text: response.message });
                    this.$router.push({ name: "shop" });
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.$notify({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async uploadLogo(shopId) {
            if (this.model.photo instanceof File) {
                let formData = new FormData();
                formData.append("file", this.model.photo);
                formData.append("shop_id", shopId);
                let response = await this.$store.dispatch(
                    "agency/shop/upload",
                    formData
                );
                return response;
            }
            return "";
        },
        clearInput() {
            this.model.shop_name = "";
            this.model.phone_number = "";
            this.model.email = "";
            this.model.business_category_id = 2; // non-food
            this.model.photo = "";
            this.model.geo_location.lat = "";
            this.model.geo_location.lng = "";
            this.model.province_code = undefined;
            this.model.district_code = undefined;
            this.model.commune_code = undefined;
            this.model.village_code = undefined;
            this.model.payment_info = [];
            this.model.acknowledge_tc = true;
            this.model.shop_type_id = 1; // normal
            this.model.refer_from_agency_id = undefined;
            this.model.is_active = true;
            this.model.exchange_rate = [];
            this.model.is_stock_at_jd = false;
            this.model.fee_per_package = 0;
            this.model.location_name = undefined;
            this.model.service_type_allow = [2]; // default standard
            this.model.express_fee_usd = "";
            this.model.standard_fee_usd = "";
            this.model.user_name = "";
            this.model.express_driver_fee_usd = "";
            this.model.standard_driver_fee_usd = "";
            this.model.password = null;
            this.model.password_confirmation = null;
            this.model.saler_id = null;
            this.model.tel_chat_id = null;
            this.$refs.basicinfo.$refs.uploadimage.logo_file = "";
            this.$refs.basicinfo.$refs.uploadimage.logo_file_src = require("@/assets/default-image.jpg");
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.fetchFormView();
        });
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit("agency/shop/RESET_STATE");
        next();
    }
};
</script>
